<template>
  <div>
    <loading v-if="Loading"/>
    <b-modal v-model="modalShow" title="Amandemen Pasal" hide-footer size="sm">
      <validation-observer ref="news" tag="form" @submit.prevent="submitAdd">
        <b-form  @submit.prevent="submitAdd">
          <loading v-if="Loading"/>
          <b-row v-if="!Loading">

            <b-col cols="12">
              <b-form-group label="Type">
                <validation-provider #default="{ errors }" name="type" rules="required">
                  <b-form-input v-model="formPasal.type" name="type" placeholder="Type" locale="id" now-button :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Pasal">
                <validation-provider #default="{ errors }" name="pasal" rules="required">
                  <b-form-input v-model="formPasal.pasal" name="pasal" placeholder="Pasal" locale="id" now-button :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Name">
                <validation-provider #default="{ errors }" name="Name" rules="required">
                  <b-form-input v-model="formPasal.name" name="name" placeholder="Name" locale="id" now-button :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Lama Penjara">
                <validation-provider #default="{ errors }" name="jail" rules="required">
                  <b-form-input v-model="formPasal.jail" name="jail" placeholder="Lama Penjara" locale="id" now-button :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Denda">
                <validation-provider #default="{ errors }" name="denda" rules="required">
                  <b-form-input v-model="formPasal.denda" name="denda" placeholder="Denda" locale="id" now-button :state="errors.length > 0 ? false : null"/>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button type="submit" variant="primary" v-if="!Loading">Submit</b-button>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- Tabel -->
    <b-row>
      <b-col class="col-8">
        <b-form-group>
          <!-- <b-button v-if="!Loading" @click="showModal" size="sm" variant="primary">Tambah User MDT</b-button> &nbsp; -->
        </b-form-group>
      </b-col>
      <b-col class="col-4">
        <b-form-group>
          <b-input v-model="filter" placeholder="Filter"></b-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row v-if="!Loading">
      <b-col class="col-12">
        <b-table striped hover :items="filteredTableData" :fields="fields" :per-page="perPage" :current-page="currentPage">
          <template v-slot:cell(action)="row">
            <b-button @click="edit(row.item)" variant="primary" size="sm">Edit</b-button>&nbsp;
          </template>
        </b-table>
        <div class="d-flex justify-content-end">
          <b-pagination v-model="currentPage" :total-rows="filteredTableData.length" :per-page="perPage"></b-pagination>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import axios from "axios";
import Loading from '@/templates/Loading.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import _interfaces from '@/templates/_interfaces';
import Multiselect from 'vue-multiselect';
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";


export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    ToastificationContent,
    Multiselect,
    axios,
    quillEditor
  },
  data() {
    return {
      Loading: false,
      perPage: 10, // Jumlah item per halaman
      currentPage: 1, // Halaman saat ini
      filter: null,
      fields: [
        {key:"type_name",text:"Type"},
        {key:"pasal",text:"Pasal"},
        {key:"name",text:"Pasal"},
        {key:"jail",text:"Pasal"},
        {key:"denda",text:"Pasal"},
        {key:"action",text:"Action"},
      ],
      statusOption: [
        { text: 'Tampil', value: 1 },
        { text: 'Tidak Tampil', value: 0 }
      ],
      modalShow: false,
      formPasal: _interfaces.master_pasal,
      tableData: [],
      dataWarga: [],
      content: '',
      isEdit: false,
      editorOptions: {
        modules: {
          toolbar: [
            [{ 'list': 'bullet' }, { 'list': 'ordered' }], // Untuk daftar tak berurut dan berurut
            ['bold', 'italic'] // Untuk bold dan italic
          ]
        }
      }
    };
  },
  computed: {
    filteredTableData() {
      if(this.filter == null){
        return this.tableData;
      }else{
        return this.tableData.filter(item => {
          return item.name.toLowerCase().includes(this.filter.toLowerCase())
        });
      }
    },
  },
  methods: {
    init(){
      this.getData();
    },
    showModal() {
      this.isEdit = false;
      this.formNews.title = null
      this.content = null
      this.modalShow = true;
    },
    getData() {
      axios.post("idp/master/pasal/list",{id:null}).then((response) => {
        this.tableData = response.data.data
      }).catch((error) => {
        if (error.status === 4000) {
          localStorage.clear();
          this.$router.push({ path: "/login" });
          ({
            title: "Login Expired",
            message: "Sesi Login Habis",
          });
        }
      }).finally(() => {
        this.Loading = false;
        this.modalShow = false;
      });
    },
    submitAdd() {
      this.$refs.news.validate().then((success) => {
        if(success){      
          
          this.$swal({
            title: "Anda Yakin melakukan Update?",
            icon: "question",
            background: "black",
            showCancelButton: true,
            confirmButtonColor: "#7367f0",
            cancelButtonColor: "#ea5455",
            confirmButtonText: "Save",
          }).then((result) => {
            if (result.isConfirmed) {  
              this.Loading = true;
              setTimeout(() => {

                if(this.isEdit == false){
                  axios.post("idp/master/pasal/add",this.formPasal).then((response) => {
                    
                  }).catch((error) => {
                    if (error.status === 4000) {
                      localStorage.clear();
                      this.$router.push({ path: "/login" });
                      ({
                        title: "Login Expired",
                        message: "Sesi Login Habis",
                      });
                    }
                  }).finally(() => {
                    this.Loading = false;
                    this.modalShow = false;
                    this.init();
                  });
                }else{
                  axios.post("idp/master/pasal/edit",this.formPasal).then((response) => {
                    
                  }).catch((error) => {
                    if (error.status === 4000) {
                      localStorage.clear();
                      this.$router.push({ path: "/login" });
                      ({
                        title: "Login Expired",
                        message: "Sesi Login Habis",
                      });
                    }
                  }).finally(() => {
                    this.Loading = false;
                    this.modalShow = false;
                    this.init();
                  });
                }
              }, 1000);
            } else {
            
            }
          });
        }
      });
    },
    edit(row){
      this.isEdit = true;
      this.modalShow = true;
      this.formPasal.id = row.id;
      this.formPasal.type = row.type;
      this.formPasal.pasal = row.pasal;
      this.formPasal.name = row.name;
      this.formPasal.jail = row.jail;
      this.formPasal.denda = row.denda;
    }
  },
  mounted(){
    this.init();
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>